import { SearchOutlined } from "@ant-design/icons";
import { Button, Input, List, Spin, Empty, Divider } from "antd";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import debounce from "lodash/debounce";
import _ from "lodash";

import { searchController } from "../../features/search/searchSlice";
import { getConfig } from "../../services/apiUtils";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

function AppSearchBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  const {
    searchResult = [],
    isLoading,
    error,
  } = useSelector((state) => state.search);

  const [searchValue, setSearchValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchType, setSearchType] = useState("track"); // Default category

  // Debounced search function
  const fetchSearchResults = useCallback(
    debounce(async (query, type) => {
      let limit = 4;
      if (!query.trim()) return;

      try {
        await dispatch(
          searchController.getSearch({
            payload: { query, type, limit }, // Include search type
            config: getConfig(),
          })
        ).unwrap();
      } catch (error) {
        console.error("Failed to fetch search results:", error);
      }
    }, 300),
    [dispatch]
  );

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (value) {
      fetchSearchResults(value, searchType);
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
    }
  };

  // Handle search category change
  const handleCategoryChange = (type) => {
    setSearchType(type);
    if (searchValue) {
      fetchSearchResults(searchValue, type);
    }
  };

  // Handle pressing Enter
  const handleKeyPress = (e) => {
    if (e.key === "Enter" && searchValue.trim()) {
      navigate(`/search?query=${searchValue.trim()}&type=${searchType}`);
      setShowDropdown(false);
    }
  };

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Navigate and close dropdown safely
  const handleItemClick = (route) => {
    try {
      navigate(route);
      setShowDropdown(false);
    } catch (error) {
      console.error("Navigation error:", error);
    }
  };

  return (
    <div className="w-100 position-relative">
      {/* Search Input */}
      <Input
        placeholder="What do you feel like listening to right now?"
        value={searchValue}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        suffix={
          <Button
            variant="solid"
            color="default"
            shape="circle"
            onClick={() =>
              searchValue.trim() &&
              navigate(`/search?query=${searchValue.trim()}&type=${searchType}`)
            }
          >
            <SearchOutlined />
          </Button>
        }
        className="rounded-pill"
      />

      {/* Search Results Dropdown */}
      {showDropdown && searchValue && (
        <div
          ref={dropdownRef}
          className="position-absolute w-100 mt-2 pb-2 rounded-4 shadow"
          style={{
            zIndex: 9999,
            top: "100%",
            backgroundColor: "#fff",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <div className="pt-3 px-4 d-flex gap-1">
            {["track", "album", "artist"].map((type) => (
              <Button
                key={type}
                shape="round"
                type={searchType === type ? "primary" : "default"} // Highlight selected type
                onClick={() => handleCategoryChange(type)}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
              </Button>
            ))}
          </div>
          <Divider className="mb-0" />
          <div className="px-4 py-2 d-flex justify-content-between align-items-center w-100">
            <span>Can't find a song? Add it here!</span>
            <Button type="primary" className="rounded-pill px-4 py-1">
              Add Song
            </Button>
          </div>
          <Divider className="mt-0" />
          {isLoading ? (
            <Spin className="d-flex justify-content-center p-4" />
          ) : error ? (
            <Empty description="Failed to load results" />
          ) : Array.isArray(searchResult) && searchResult.length > 0 ? (
            <div className="px-4">
              <List
                dataSource={searchResult?.length > 0 ? searchResult : []}
                renderItem={(item) => {
                  if (!item) return null;

                  const songName =
                    item?.song_name ||
                    item?.album_name ||
                    item?.artist_name ||
                    "Unknown";
                  const artistName =
                    item?.artistDetails?.artist_name ||
                    item?.artist_name ||
                    "Unknown Artist";

                  // 🔹 Dynamic Navigation Path based on `searchType`
                  let navigatePath = "";
                  if (
                    searchType === "track" &&
                    item.song_name &&
                    (item.isrc_code || item.song_id)
                  ) {
                    navigatePath = generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                      songName: _.kebabCase(item.song_name),
                      isrcKey: item?.isrc_code || item?.song_id,
                    });
                  } else if (
                    searchType === "album" &&
                    item.album_name &&
                    item.album_id
                  ) {
                    navigatePath = generateDynamicRoute(
                      ROUTES.MAIN.ALBUM_SONGS,
                      {
                        albumName: _.kebabCase(item.album_name),
                        albumId: item.album_id,
                      }
                    );
                  } else if (
                    searchType === "artist" &&
                    item.artist_name &&
                    item.artist_id
                  ) {
                    navigatePath = generateDynamicRoute(
                      ROUTES.MAIN.VIEW_ARTIST_BIO,
                      {
                        artistName: _.kebabCase(item.artist_name),
                        artistId: item.artist_id,
                      }
                    );
                  }

                  return (
                    <List.Item
                      className="border-0 py-2 cursor-pointer"
                      onClick={() => {
                        if (!navigatePath) {
                          console.warn(
                            "Missing necessary fields for navigation:",
                            item
                          );
                          return;
                        }
                        handleItemClick(navigatePath);
                      }}
                    >
                      <div className="d-flex flex-column gap-1 w-100">
                        <span
                          className="fw-bold fs-6"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {songName}
                        </span>
                        <span
                          className="text-muted fw-medium fs-sm"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          {artistName}
                        </span>
                      </div>
                    </List.Item>
                  );
                }}
              />
            </div>
          ) : (
            <Empty description="No results found" />
          )}
        </div>
      )}
    </div>
  );
}

export default AppSearchBar;
