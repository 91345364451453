import { showToast } from "../../components/_toasts/notificationUtils";
import { createApiThunk } from "../../utils/thunkUtils";

export const getSearch = createApiThunk(
  "search/getSearch",
  { method: "GET", endpoint: "/user/search-song-artist-album" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const getSearchResults = createApiThunk(
  "search/getSearchResults",
  { method: "GET", endpoint: "/user/search-result" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const getSearchSong = createApiThunk(
  "search/getSearchSong",
  { method: "GET", endpoint: "/user/search-song" },
  ({ data }) => {
    // showToast("success", data.message, 2);
  }
);

export const searchThunks = {
  getSearchResults,
  getSearch,
  getSearchSong,
};
