import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Spin } from "antd";
import { motion } from "framer-motion";
import { MdArrowForward } from "react-icons/md";
import Confetti from "react-confetti";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { StaticImages } from "../../../utils/constants";
import { getAuthConfig } from "../../../services/apiUtils";
import QuizCard from "./QuizCard";
import { controller } from "../../../services/apiServices";
import QuizCommentSection from "./QuizCommentSection";
import utilities from "../../../utils/utilities";

function QuizPage() {
  const [score, setScore] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);

  const fetchQuestion = async () => {
    setLoading(true);
    try {
      const payload = {
        genre: "all",
      };
      const config = getAuthConfig();
      const response = await controller.quiz.getQuiz(payload, config);
      if (response.success) {
        setCurrentQuestion(response.data);
        setSelectedAnswer(null);
        setIsAnswerChecked(false);
      }
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch question:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setScore(0); // Reset score
    setTotalQuestions(0); // Reset total questions
    fetchQuestion();
  }, []);

  const handleAnswerSelect = (selectedOption) => {
    setSelectedAnswer(selectedOption);
    setIsAnswerChecked(true);

    if (currentQuestion.correctAnswer === selectedOption) {
      setScore((prevScore) => prevScore + 1);
      setFeedback("Correct! Great job 🎉"); // Show positive feedback
      setShowConfetti(true); // Trigger confetti
      setTimeout(() => setShowConfetti(false), 3000); // Stop confetti after 3 seconds
    } else {
      setFeedback(
        `Wrong! The correct answer was ${currentQuestion.correctAnswer}.`
      ); // Show negative feedback
    }

    setTotalQuestions((prevTotal) => prevTotal + 1); // Increment total questions
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    setSelectedAnswer(null);
    setIsAnswerChecked(false);
    setFeedback(""); // Reset feedback
    fetchQuestion();
  };

  const renderOptions = () => {
    if (!currentQuestion) return null;

    const { correctAnswer } = currentQuestion;

    return ["A", "B", "C", "D"].map((option, index) => {
      const optionKey = `option${option}`;
      const isCorrect = option === correctAnswer;
      const isSelected = option === selectedAnswer;
      const showCorrectGreen = isAnswerChecked && isCorrect;
      const showIncorrectRed = isAnswerChecked && isSelected && !isCorrect;

      return (
        <motion.div
          key={index}
          whileHover={{
            backgroundColor: "#E6E6FA",
            scale: 1.02,
            transition: { duration: 0.3 },
            cursor: "pointer",
            color: "black",
          }}
          whileTap={{ scale: 0.98 }}
          className="d-flex align-items-center mb-3 rounded-4"
          style={{
            backgroundColor: showCorrectGreen
              ? "#32FF78"
              : showIncorrectRed
              ? "#FF4081"
              : "transparent",
            border: `2px solid ${
              isSelected || isCorrect ? "#007BFF" : "cornflowerblue"
            }`,
            padding: "10px 15px",
            width: "100%",
            color:
              isSelected || showCorrectGreen || showIncorrectRed
                ? "black"
                : "white", // Updated font color
          }}
        >
          <div
            className="rounded-3 text-uppercase me-3 d-flex align-items-center justify-content-center bg-opulentBlue text-white"
            style={{
              width: "40px",
              height: "40px",
              border: `2px solid cornflowerblue`,
            }}
          >
            {utilities.stringUtils.formatStringType(option, "lowerCase")}
          </div>
          <Button
            variant="none"
            className="w-100 text-start"
            onClick={() => handleAnswerSelect(option)}
            disabled={isAnswerChecked}
            style={{
              background: "transparent",
              border: "none",
              padding: "0",
              color: "inherit",
              fontSize: "16px",
            }}
          >
            {currentQuestion[optionKey]}
          </Button>
        </motion.div>
      );
    });
  };

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>
        <div className="m-4 m-lg-2">
          <div
            className={`position-relative bg-sliceHeaven rounded-4 p-2 p-lg-5 p-md-4 p-sm-3`}
            style={{
              backgroundImage: `url(${StaticImages.Utils.MusicMasterQuizBg})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "50% auto",
              backgroundPosition: "left center",
            }}
          >
            <Row className="m-0 p-0">
              {showConfetti && (
                <Confetti
                  width={window.innerWidth} // Optional: Use `useWindowSize` for responsive size
                  height={window.innerHeight}
                  numberOfPieces={200} // Number of confetti pieces
                  gravity={0.2} // Adjust gravity for effect
                />
              )}
              <Col xs={12} className="px-3 px-md-4 py-3 py-lg-4">
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start">
                  <h2 className="fw-bold text-white mb-3 mb-sm-0">
                    Let’s play a Quiz
                  </h2>
                </div>
              </Col>

              <div className="d-flex justify-content-between align-items-center mb-4">
                <p className="fw-bold text-white mb-0">Score: {score}</p>
                <p className="fw-bold text-white mb-0">
                  Total Questions: {totalQuestions}
                </p>
              </div>
              {/* Feedback message */}
              {feedback && (
                <h4 className="mb-4 text-center">
                  <p className="text-white fw-bold">{feedback}</p>
                </h4>
              )}

              {loading ? (
                <Col
                  xs={12}
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "50vh" }}
                >
                  <Spin tip="Loading..." size="large" />
                </Col>
              ) : currentQuestion ? (
                <>
                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="px-3 px-md-4 py-3 py-lg-4 d-flex flex-column justify-content-center"
                  >
                    <p className="fw-bold text-white mb-3">
                      Question {currentQuestionIndex + 1}
                    </p>
                    <h3 className="text-white mb-3">
                      {currentQuestion.question}
                    </h3>
                  </Col>

                  <Col
                    lg={6}
                    md={6}
                    sm={12}
                    xs={12}
                    className="px-3 px-md-4 py-3 py-lg-4"
                  >
                    <Row className="g-3">{renderOptions()}</Row>
                  </Col>
                </>
              ) : null}
            </Row>

            <div className="d-flex justify-content-end px-3 px-md-4 py-3">
              <Button
                variant="light"
                className={`text-sliceHeaven fw-bold rounded-4 px-4 py-2`}
                onClick={handleNextQuestion}
                disabled={!currentQuestion || loading}
              >
                Next Question <MdArrowForward className="ms-2 fw-bold" />
              </Button>
            </div>
          </div>
        </div>
        <div className="m-4 m-lg-2">
          <Row className="p-4">
            <Col sm={12} md={4} className="mb-3">
              <QuizCard />
            </Col>
            <Col sm={12} md={8}>
              <QuizCommentSection currentQuestion={currentQuestion} />
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default QuizPage;
