import React from "react";
import { Row, Col, Card, Button, Spinner, Alert, Image } from "react-bootstrap";
import { motion } from "framer-motion";
import { FaPlay } from "react-icons/fa6";
import _ from "lodash";
import { useSelector } from "react-redux";

import styles from "./FeaturedMusicVideo.module.css";
import { StaticImages } from "../../utils/constants";

const FeaturedMusicVideo = ({ genreType = "all" }) => {
  const {
    featuredVideos = [],
    isLoading,
    error,
  } = useSelector((state) => state.config);

  // Utility function to format date
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  if (isLoading) {
    return <Spinner animation="border" role="status" className="my-4" />;
  }

  if (error) {
    return <Alert variant="danger">Failed to load videos: {error}</Alert>;
  }

  if (featuredVideos.length === 0) {
    return <p className="my-4">No videos available to display.</p>;
  }

  return (
    <div className="my-4">
      <h1 className="fw-bold mb-4">
        Featured {genreType !== "all" ? _.capitalize(genreType) : ""} Music
        Videos
      </h1>
      <Row className="g-4">
        {/* Large featured video */}
        <Col lg={8}>
          {featuredVideos[0] && (
            <Card className="border-0 bg-ghostWhite">
              <div className={styles.largeVideoContainer}>
                <iframe
                  src={`https://www.youtube.com/embed/${featuredVideos[0].videoId}`}
                  title={featuredVideos[0].title}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="w-100 h-100 rounded-4"
                ></iframe>
              </div>
              <Card.Body>
                <Row>
                  <Col lg={8}>
                    <h4 className="fw-bold">{featuredVideos[0].title}</h4>
                    <p className="text-muted">
                      {featuredVideos[0].description}
                    </p>
                  </Col>
                  <Col lg={4}>
                    <p className="text-muted">
                      By <strong>{featuredVideos[0].channelTitle}</strong> /{" "}
                      {formatDate(featuredVideos[0].publishTime)}
                    </p>
                    <motion.div
                      whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.3 },
                      }}
                    >
                      <Button
                        variant="eigengrau"
                        size="sm"
                        className="mt-2 d-flex align-items-center"
                        onClick={() =>
                          window.open(
                            `https://www.youtube.com/watch?v=${featuredVideos[0].videoId}`,
                            "_blank"
                          )
                        }
                      >
                        <FaPlay className="mx-1" />
                        <span>Watch Now</span>
                      </Button>
                    </motion.div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>

        {/* Small video listing */}
        <Col lg={4}>
          {featuredVideos.slice(1).map((video) => (
            <Card key={video._id} className="mb-3 border-0 bg-ghostWhite">
              <Row className="g-0 align-items-center">
                <Col xs={4} className="align-self-start">
                  <div className={styles.smallVideoContainer}>
                    <Image
                      fluid
                      src={video.thumbnail || StaticImages.App.AppDefaultS}
                      alt={video.title}
                      className="rounded-3"
                      style={{ objectFit: "cover" }}
                      loading="lazy"
                    />
                  </div>
                </Col>
                <Col xs={8}>
                  <Card.Body className="p-2">
                    <p
                      className="text-muted mb-1"
                      style={{ fontSize: "0.75rem" }}
                    >
                      By {video.channelTitle} / {formatDate(video.publishTime)}
                    </p>
                    <h6 className="fw-bold mb-1">{video.title}</h6>

                    <Button
                      as={motion.button}
                      variant="eigengrau"
                      size="sm"
                      className="mt-2 d-flex align-items-center"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      transition={{
                        type: "spring",
                        stiffness: 300,
                        damping: 20,
                      }}
                      onClick={() =>
                        window.open(
                          `https://www.youtube.com/watch?v=${video.videoId}`,
                          "_blank"
                        )
                      }
                    >
                      <FaPlay className="mx-1" />
                      <span>Watch Now</span>
                    </Button>
                  </Card.Body>
                </Col>
              </Row>
            </Card>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default FeaturedMusicVideo;
