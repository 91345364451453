import React, { memo } from "react";
import { Button, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { motion } from "framer-motion";
import { BsMusicNoteList } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { DeleteFilled } from "@ant-design/icons";
import { Button as AntButton, Popconfirm } from "antd";

import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { StaticImages } from "../../utils/constants";
import { isAuthenticated } from "../../utils/authHelpers";
import utilities from "../../utils/utilities";

const PlaylistCard = ({
  playlist = {},
  index = 0,
  type = "default",
  user = "all",
  handleDelete,
}) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.stopPropagation(); // Prevent event bubbling
    if (!playlist?._id || !playlist?.playlist_name) return; // Safety check
    navigate(
      generateDynamicRoute(
        user === "all"
          ? ROUTES.MAIN.PLAYLIST_SONGS
          : ROUTES.MAIN.USER_PLAYLIST_SONGS,
        {
          playlistName: utilities.stringUtils.formatStringType(
            playlist.playlist_name,
            "kebabCase"
          ),
          playlistId: playlist._id,
        }
      )
    );
  };

  const renderOverlay = (name) => (
    <Tooltip id={`tooltip-${index}`}>{name || `Playlist ${index + 1}`}</Tooltip>
  );

  const playlistName = playlist?.playlist_name || `Playlist ${index + 1}`;
  const playlistImage =
    playlist?.playlist_image || StaticImages.App.AppDefaultS; // Fallback image

  return (
    <Card
      className={`rounded-4 p-3 bg-white overflow-hidden ${
        type === "card"
          ? "playlist-card border-0"
          : "playlist-list-item border-1"
      }`}
      onClick={handleClick}
      role="button"
      aria-label={`Open ${playlistName}`}
    >
      {type === "card" ? (
        <motion.div
          className="position-relative"
          initial={{ scale: 1 }}
          whileHover={{ scale: 1.02 }}
        >
          {/* Playlist Image */}
          <Image
            fluid
            src={playlistImage}
            alt={playlistName}
            className="w-100 rounded-4"
            loading="lazy"
          />

          {/* Hover Effect for the Button */}
          <motion.div
            className="position-absolute bottom-0 start-0 w-100 h-100 d-flex align-items-end p-2 bg-overlay"
            initial={{ opacity: 0 }}
            whileHover={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <Button
              variant="light"
              className="fw-bold rounded-3"
              size="sm"
              onClick={handleClick}
              aria-label={`View ${playlistName}`}
            >
              <BsMusicNoteList className="me-2" />
              View Playlist
            </Button>
          </motion.div>
        </motion.div>
      ) : (
        <div className="d-flex align-items-center">
          {/* Playlist Thumbnail */}
          <Image
            fluid
            src={playlistImage}
            alt={playlistName}
            height={75}
            width={75}
            rounded
            className="me-3"
            loading="lazy"
          />
          {/* Playlist Details */}
          <div className="d-flex flex-column">
            <OverlayTrigger
              placement="top-start"
              overlay={renderOverlay(playlistName)}
            >
              <div
                className="text-truncate fw-semibold my-2 fs-6"
                // title={playlistName}
              >
                {playlistName}
              </div>
            </OverlayTrigger>

            <Button
              as={motion.button}
              variant="eigengrau"
              className="rounded-3 mt-1"
              size="sm"
              onClick={handleClick}
              aria-label={`View songs in ${playlistName}`}
            >
              <BsMusicNoteList className="me-2" />
              View Songs
            </Button>
          </div>
        </div>
      )}
      {type === "card" && (
        <OverlayTrigger
          placement="top-start"
          overlay={renderOverlay(playlistName)}
        >
          <Card.Text className="d-flex align-items-center justify-content-between text-truncate fw-semibold mt-2">
            <span className="flex-grow-1 text-truncate">{playlistName}</span>
            {isAuthenticated().isUserAuthenticated && user === "user" && (
              <span>
                {/* <AntButton type="text" onClick={(e) => e.stopPropagation()}>
                  <EditFilled />
                </AntButton> */}
                <Popconfirm
                  title="Are you sure you want to delete this playlist?"
                  onConfirm={(e) => {
                    e.stopPropagation();
                    handleDelete(playlist._id);
                  }}
                  onCancel={(e) => e.stopPropagation()}
                  okText="Yes"
                  cancelText="No"
                >
                  <AntButton
                    type="text"
                    danger
                    onClick={(e) => e.stopPropagation()}
                  >
                    <DeleteFilled />
                  </AntButton>
                </Popconfirm>
              </span>
            )}
          </Card.Text>
        </OverlayTrigger>
      )}
    </Card>
  );
};

export default memo(PlaylistCard);
