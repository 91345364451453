import React, { useCallback, useEffect, useState } from "react";
import { DislikeOutlined, LikeOutlined, SendOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, List, Space, Spin, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

import { getAuthToken } from "../../utils/authHelpers";
import { ROUTES } from "../../routes/constants";
import { getAuthConfig } from "../../services/apiUtils";
import { showToast } from "../_toasts/notificationUtils";
import { controller } from "../../services/apiServices";
import utilities from "../../utils/utilities";

function ViewLyricsCommentSection() {
  const navigate = useNavigate();
  const { isrcKey } = useParams();
  const [checkToken, setCheckToken] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [error, setError] = useState();
  const [commentList, setCommentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);

  // Fetch comments
  const getCommentList = useCallback(async () => {
    try {
      const payload = { isrc: isrcKey };
      const config = getAuthConfig();
      const response = await controller.lyrics.getUserCommentLyrics(
        payload,
        config
      );
      if (response.status === 200) {
        setCommentList(response.data.comments);
      } else {
        setCommentList([]);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  }, [isrcKey]);

  useEffect(() => {
    const token = getAuthToken();
    if (token) {
      setCheckToken(true);
      const user = jwtDecode(token);
      setUserId(user?._id);
    } else {
      setCheckToken(false);
    }
  }, []);

  // Submit comment
  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!commentValue.trim()) {
      toast.error("Comment cannot be empty.");
      setError("Comment box cannot be empty.");
      return;
    }
    setLoading(true);
    try {
      const payload = { isrc: isrcKey, comment: commentValue.trim() };
      const config = getAuthConfig();
      const response = await controller.lyrics.addUserCommentLyrics(
        payload,
        config
      );
      if (response.status === 201) {
        getCommentList();
        setCommentValue("");
      } else {
        showToast("error", response.message, 2);
      }
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      showToast("error", error.message || "Failed to submit comment.", 2);
    } finally {
      setLoading(false);
    }
  };

  // Handle like/dislike
  const handleLikeDislike = async (commentId, isLike) => {
    if (!checkToken) {
      return showToast("error", "Please login to like or dislike.", 2);
    }
    try {
      const payload = { commentId, isLike }; // `action` can be "like" or "dislike"
      const response = await controller.lyrics.reactLikeDislikeSongComment(
        payload,
        getAuthConfig(),
        getCommentList()
      );

      console.log("Response:", response);

      if (response.status === 200) {
        getCommentList();
      } else {
        // showToast(
        //   "error",
        //   response.message || "Failed to process reaction.",
        //   2
        // );
      }
    } catch (error) {
      console.error("Error in like/dislike:", error);
      // showToast("error", error.message || "Error processing reaction.", 2);
    }
  };

  // Delete comment
  const handleDeleteComment = async (commentId) => {
    try {
      const response = await controller.lyrics.deleteUserCommentLyrics(
        { commentId },
        getAuthConfig()
      );
      if (response.status === 200) {
        getCommentList();
      } else {
        showToast("error", response.message || "Failed to delete comment.", 2);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
      showToast("error", error.message || "Error deleting comment.", 2);
    }
  };

  useEffect(() => {
    if (commentValue.length > 0) {
      setError("");
    }
  }, [commentValue]);

  useEffect(() => {
    getCommentList();
  }, [getCommentList]);

  return (
    <div>
      <h1 className="d-flex justify-content-between align-items-center">
        <span className="fw-bold">Comments</span>
        {!checkToken && (
          <Button
            variant="solid"
            color="default"
            size="large"
            onClick={() => navigate(ROUTES.AUTH.LOGIN)}
          >
            Sign In
          </Button>
        )}
      </h1>
      {/* Input Field for Comment */}
      <div className="my-3">
        <Input
          placeholder="Add a comment..."
          size="large"
          value={commentValue}
          disabled={!checkToken}
          onChange={(e) => setCommentValue(e.target.value)}
          suffix={
            <Button
              color="default"
              variant="text"
              disabled={!checkToken}
              onClick={handleCommentSubmit}
            >
              {loading ? <Spin /> : <SendOutlined />}
            </Button>
          }
        />
        {error && (
          <div className="text-danger fw-bold text-center mt-2">{error}</div>
        )}
      </div>
      {/* List of Comments */}
      <List
        bordered={false}
        dataSource={commentList}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item?.user?.avatar} size={50} />}
              title={
                <Space direction="vertical" size={0}>
                  <Typography.Text strong>
                    {item?.user?.username}
                  </Typography.Text>
                </Space>
              }
              description={
                <>
                  <div style={{ marginBottom: "8px" }}>{item?.comment}</div>
                  <div className="d-flex justify-content-between align-items-center">
                    <Space size="large">
                      <Space>
                        <LikeOutlined
                          onClick={() => handleLikeDislike(item?._id, true)}
                          style={{ cursor: "pointer", color: "blue" }}
                        />
                        {item?.likes?.length > 0 && item?.likes?.length}
                      </Space>
                      <Space>
                        <DislikeOutlined
                          onClick={() => handleLikeDislike(item._id, false)}
                          style={{ cursor: "pointer", color: "red" }}
                        />
                        {item?.dislikes?.length > 0 && item?.dislikes?.length}
                      </Space>
                      {item?.user?._id === userId && (
                        <>
                          <Space>
                            <Button
                              danger
                              type="text"
                              size="small"
                              onClick={() => handleDeleteComment(item._id)}
                            >
                              Delete
                            </Button>
                          </Space>
                        </>
                      )}
                    </Space>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: "12px" }}
                    >
                      {utilities.dateUtils.getRelativeTime(item?.createdAt)}
                    </Typography.Text>
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
}

export default ViewLyricsCommentSection;
