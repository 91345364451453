import _ from "lodash";
import {
  parseISO,
  differenceInWeeks,
  differenceInMonths,
  differenceInDays,
  differenceInYears,
} from "date-fns";
import axios from "axios";

import { getAuthConfig } from "../services/apiUtils";

/* ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------ */

// dateUtils.js
/**
 * Utility function to format a date string into "dd.MM.yyyy" format.
 *
 * This function takes a date string, creates a `Date` object, and returns
 * the date formatted as "DD.MM.YYYY". The day and month are padded to ensure
 * they are always two digits.
 *
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} The formatted date in "DD.MM.YYYY" format.
 *
 * @example
 * formatDate('2024-10-15T12:00:00Z');
 * // returns "15.10.2024"
 */
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = _.padStart(date.getDate(), 2, "0"); // Ensure two-digit day
  const month = _.padStart(date.getMonth() + 1, 2, "0"); // Month is 0-indexed
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
};

/**
 * Utility function to format a date string into "yyyy-MM-dd" format.
 *
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} The formatted date in "YYYY-MM-DD" format.
 *
 * @example
 * formatDateISO('2024-10-15T12:00:00Z');
 * // returns "2024-10-15"
 */
export const formatDateISO = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = _.padStart(date.getMonth() + 1, 2, "0"); // Month is 0-indexed
  const day = _.padStart(date.getDate(), 2, "0");
  return `${year}-${month}-${day}`;
};

/**
 * Utility function to format a date string into "dd-MMM-yyyy" format.
 *
 * This function takes a date string and returns it in the format "DD-MMM-YYYY"
 * (e.g., "12-Jan-2024").
 *
 * @param {string} dateString - The input date string to be formatted.
 * @returns {string} The formatted date in "DD-MMM-YYYY" format.
 *
 * @example
 * formatDateWithMonthAbbreviation('2024-10-15T12:00:00Z');
 * // returns "15-Oct-2024"
 */
export const formatDateWithMonthAbbreviation = (dateString) => {
  const date = new Date(dateString);
  const day = _.padStart(date.getDate(), 2, "0"); // Ensure two-digit day
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = monthNames[date.getMonth()]; // Get month abbreviation
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export function getRelativeTime(apiDate) {
  const currentDate = new Date();
  const pastDate = parseISO(apiDate);

  const daysAgo = differenceInDays(currentDate, pastDate);
  const weeksAgo = differenceInWeeks(currentDate, pastDate);
  const monthsAgo = differenceInMonths(currentDate, pastDate);
  const yearsAgo = differenceInYears(currentDate, pastDate);

  if (yearsAgo >= 1) {
    return yearsAgo === 1 ? "1 year ago" : `${yearsAgo} years ago`;
  } else if (monthsAgo >= 1) {
    return monthsAgo === 1 ? "1 month ago" : `${monthsAgo} months ago`;
  } else if (weeksAgo >= 1) {
    return weeksAgo === 1 ? "1 week ago" : `${weeksAgo} weeks ago`;
  } else if (daysAgo >= 1) {
    return daysAgo === 1 ? "1 day ago" : `${daysAgo} days ago`;
  } else {
    return "Today";
  }
}

// Exporting as dateUtils object
export const dateUtils = {
  formatDate,
  formatDateISO,
  formatDateWithMonthAbbreviation,
  getRelativeTime,
};

/* ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------ */

// src/utils/fileUtils.js

const baseURL =
  `${process.env.REACT_APP_API_BASE_URL}` || "https://v2.lyricsweb.com/api/v1";

/**
 * Upload a file using FormData.
 *
 * @param {File} file - The file to upload.
 * @param {string} endpoint - The API endpoint for uploading the file.
 * @returns {Promise<string>} - The URL of the uploaded file.
 */
export const uploadFile = async (file, endpoint, fieldName) => {
  const formData = new FormData();
  formData.append(fieldName, file);

  try {
    const { data } = await axios.post(
      `${baseURL}${endpoint}`,
      formData,
      getAuthConfig("multipart/form-data")
    );
    return data.data.path; // Adjust based on your API response
  } catch (error) {
    console.error("Error Uploading File:", error);
    throw new Error("File upload failed");
  }
};

export const fileUtils = { uploadFile };

/* ------------------------------------------------------------------------------------------ */
/* ------------------------------------------------------------------------------------------ */

// stringUtils.js

/**
 * Helper function to format strings based on a transformation type.
 * - Converts kebab-case to Start Case (default).
 * - Can be extended for other transformations (e.g., camelCase, snake_case, etc.).
 *
 * @param {string} input - The string to be formatted.
 * @param {string} type - The type of transformation ('startCase' is default).
 * @returns {string} - The transformed string.
 */
export const formatStringType = (input, type = "startCase") => {
  if (!input) return "";

  // Transform input based on the provided type
  switch (type) {
    case "startCase":
      return _.startCase(_.toLower(input)); // Converts 'apna-bana-le' to 'Apna Bana Le'
    case "kebabCase":
      return _.kebabCase(input); // Converts 'Apna Bana Le' to 'apna-bana-le'
    case "camelCase":
      return _.camelCase(input); // Converts 'Apna Bana Le' to 'apnaBanaLe'
    case "snakeCase":
      return _.snakeCase(input); // Converts 'Apna Bana Le' to 'apna_bana_le'
    case "lowerCase":
      return _.toLower(input); // Converts 'Apna Bana Le' to 'apna bana le'
    case "upperCase":
      return _.toUpper(input); // Converts 'Apna Bana Le' to 'APNA BANA LE'
    default:
      return input; // Return the original if no matching case
  }
};

/**
 * Normalizes a genre string by replacing unwanted characters (hyphens, slashes, commas, and multiple spaces)
 * with a single space, and capitalizes the first letter of each word.
 *
 * @param {string} genre - The genre string to be normalized.
 * @returns {string} The normalized genre string with proper capitalization.
 *
 * @example
 * // returns "Some Genre"
 * normalizeGenre("Some-Genre");
 *
 * @example
 * // returns "Some Genre"
 * normalizeGenre("Some / Genre");
 *
 * @example
 * // returns "Some Some Genre"
 * normalizeGenre("Some, Some Genre");
 */
export function normalizeGenre(genre) {
  return _.startCase(_.replace(genre, /[-/,\s]+/g, " "));
}

/**
 * Removes special characters and accents from a string.
 *
 * @param {string} text - The text to sanitize.
 * @returns {string} - The sanitized string.
 */
const sanitizeName = (text) => {
  return _.replace(_.deburr(text), /[^a-zA-Z0-9\s]/g, ""); // Remove accents and special characters
};

export const stringUtils = {
  formatStringType,
  normalizeGenre,
  sanitizeName,
};

const utilities = {
  dateUtils,
  fileUtils,
  stringUtils,
};

export default utilities;
