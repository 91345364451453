import { Button, Col, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { BsMusicNoteList } from "react-icons/bs";
import { HeartOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { FaHeart, FaRegHeart } from "react-icons/fa6";

import { StaticImages } from "../../utils/constants";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import AddSongToUserPlaylist from "../_modals/AddSongToUserPlaylist";
import { isAuthenticated } from "../../utils/authHelpers";
import { getAuthConfig } from "../../services/apiUtils";
import { controller } from "../../services/apiServices";
import utilities from "../../utils/utilities";

function LyricsInfoCard({ lyrics, loading }) {
  const navigate = useNavigate();
  const [isFavourite, setIsFavourite] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  // Function to handle sharing
  const handleShare = async () => {
    const shareData = {
      title: lyrics?.title || "Song Title",
      text: `${lyrics?.title || "Song Title"} by ${
        lyrics?.artist?.name || "Artist Name"
      }`,
      url: window.location.href, // Current page URL
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
        console.log("Shared successfully");
      } else {
        console.error("Web Share API is not supported in this browser.");
        alert("Sharing is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing content:", error);
    }
  };

  const handleAddToFavorites = async (song_id) => {
    try {
      const response = await controller.auth.addSongToFavourite(
        { song_id },
        getAuthConfig()
      );
      if (response.status === 201) {
        setIsFavourite(true);
      }
    } catch (error) {
      // handleApiError(error);
    }
  };

  const handleRemoveFromFavorites = async (song_id) => {
    try {
      const response = await controller.auth.removeSongFromFavourite(
        { song_id },
        getAuthConfig()
      );
      if (response.status === 200) {
        setIsFavourite(false);
      }
    } catch (error) {
      // handleApiError(error);
    }
  };

  const handleAddToPlaylist = (playlistId) => {
    setIsModalVisible(false);
  };

  const checkIsFavorite = async () => {
    try {
      const response = await controller.auth.getFavoriteSongUserById(
        {
          song_id: lyrics?.songData?.song_id,
        },
        getAuthConfig()
      );

      setIsFavourite(response?.data);
    } catch (error) {
      // handleApiError(error);
    }
  };

  const addSongToRecentlyPlayed = async () => {
    try {
      await controller.auth.addSongToRecentlyPlayed(
        {
          song_id: lyrics?.songData?.song_id,
        },
        getAuthConfig()
      );
    } catch (error) {
      // handleApiError(error);
    }
  };

  useEffect(() => {
    if (isAuthenticated().isUserAuthenticated && lyrics?.songData?.song_id) {
      checkIsFavorite();
      addSongToRecentlyPlayed();
    } else {
      // navigate(ROUTES.AUTH.LOGIN);
    }
  }, [lyrics?.songData?.song_id]);

  return (
    <>
      <Spin
        spinning={loading}
        tip="Loading..."
        style={{ width: "100%" }}
        size="large"
      >
        <Row gutter={24} className="m-0 p-0 d-flex align-items-center">
          <Col lg={6} md={6} sm={8} xs={8} className="m-0 p-0">
            <div
              className="m-0 p-0"
              style={{ width: "100%", maxWidth: "200px" }}
            >
              <Image
                fluid
                src={
                  lyrics?.songData?.artist?.downloaded_image_link ||
                  StaticImages.App.AppDefaultS
                }
                alt="Artist Image"
                className="rounded-4"
                style={{
                  width: "100%",
                  aspectRatio: "1 / 1",
                  objectFit: "cover",
                }}
                loading="lazy"
              />
            </div>
          </Col>

          <Col span={24} lg={18} md={18} sm={16} xs={16}>
            <h6>
              {lyrics?.songData?.artist?.artist_name || "Artist Name"}
              {`${
                lyrics?.songData?.genre &&
                lyrics?.songData?.genre !== "Not found"
                  ? ` - ${lyrics?.songData?.genre}`
                  : lyrics?.songData?.artist?.genres
                  ? ` - ${lyrics?.songData?.artist?.genres}`
                  : ""
              }`}
            </h6>
            <h2>{lyrics?.songData?.song_name || "Song Title"}</h2>
            <h6 className="text-sliceHeaven">
              {lyrics?.songData?.views.toLocaleString()} Views
            </h6>

            <div className="d-none d-lg-block">
              <div className="my-2 d-flex gap-2">
                <Button
                  variant="solid"
                  color="default"
                  icon={<BsMusicNoteList />}
                  onClick={() =>
                    navigate(
                      generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                        artistName: utilities.stringUtils.formatStringType(
                          lyrics?.songData?.artist?.artist_name,
                          "kebabCase"
                        ),
                        artistId: lyrics?.songData?.artist?.artist_id,
                      })
                    )
                  }
                >
                  View Artist
                </Button>
                <Button
                  variant="solid"
                  color="default"
                  icon={<HeartOutlined />}
                  onClick={
                    isAuthenticated().isUserAuthenticated
                      ? () => setIsModalVisible(true)
                      : () => navigate(ROUTES.AUTH.LOGIN)
                  }
                >
                  Add to Playlist
                </Button>
                <Button
                  variant="text"
                  color="default"
                  icon={
                    isFavourite ? (
                      <FaHeart className="fs-4 fw-bold" color="red" />
                    ) : (
                      <FaRegHeart className="fs-4 fw-bold" />
                    )
                  }
                  onClick={() => {
                    isAuthenticated().isUserAuthenticated
                      ? isFavourite
                        ? handleRemoveFromFavorites(lyrics?.songData?.song_id)
                        : handleAddToFavorites(lyrics?.songData?.song_id)
                      : navigate(ROUTES.AUTH.LOGIN);
                  }}
                />
                <Button
                  variant="text"
                  color="default"
                  icon={<ShareAltOutlined className="fs-4 fw-bold" />}
                  onClick={handleShare}
                />
              </div>
            </div>
          </Col>
          <div className="d-block d-lg-none mt-3">
            {/* First Row */}
            <div className="d-flex gap-3 mb-3">
              <Button
                variant="solid"
                color="default"
                icon={<BsMusicNoteList />}
                size="large"
                onClick={() =>
                  navigate(
                    generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                      artistName: utilities.stringUtils.formatStringType(
                        lyrics?.songData?.artist?.artist_name,
                        "kebabCase"
                      ),
                      artistId: lyrics?.songData?.artist?.artist_id,
                    })
                  )
                }
              >
                View Artist
              </Button>
              <Button
                variant="solid"
                color="default"
                size="large"
                icon={<HeartOutlined />}
                onClick={
                  isAuthenticated().isUserAuthenticated
                    ? () => setIsModalVisible(true)
                    : () => navigate(ROUTES.AUTH.LOGIN)
                }
              >
                Add to Playlist
              </Button>
            </div>

            {/* Second Row */}
            <div className="d-flex gap-3">
              <Button
                variant="text"
                color="default"
                size="large"
                icon={
                  isFavourite ? (
                    <FaHeart className="fs-4 fw-bold" color="red" />
                  ) : (
                    <FaRegHeart className="fs-4 fw-bold" />
                  )
                }
                onClick={() => {
                  isAuthenticated().isUserAuthenticated
                    ? isFavourite
                      ? handleRemoveFromFavorites(lyrics?.songData?.song_id)
                      : handleAddToFavorites(lyrics?.songData?.song_id)
                    : navigate(ROUTES.AUTH.LOGIN);
                }}
              />
              <Button
                variant="text"
                color="default"
                size="large"
                icon={<ShareAltOutlined className="fs-4 fw-bold" />}
                onClick={handleShare}
              />
            </div>
          </div>
        </Row>
      </Spin>

      {isAuthenticated().isUserAuthenticated && (
        <AddSongToUserPlaylist
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onAddToPlaylist={handleAddToPlaylist}
          lyrics={lyrics}
        />
      )}
    </>
  );
}

export default LyricsInfoCard;
