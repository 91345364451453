import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Upload, Spin, Avatar, DatePicker } from "antd";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Col, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";

import { authController } from "../../features/auth/authSlice";
import { getAuthConfig } from "../../services/apiUtils";
import RenderField from "../_fields/RenderField";
import { formFieldTypes } from "../../utils/formFieldTypes";
import { validationSchemas } from "../../utils/validationSchemas";
import utilities from "../../utils/utilities";

function EditProfileSection() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchemas.editProfileSchema),
    defaultValues: {
      dateOfBirth: userProfile?.dateOfBirth ? userProfile?.dateOfBirth : null,
    },
  });

  const fetchUserProfile = useCallback(async () => {
    try {
      const { status, data } = await dispatch(
        authController.getUserProfile({ config: getAuthConfig(), navigate })
      ).unwrap();

      if (status === 200) {
        const formattedData = {
          ...data.user,
          dateOfBirth: data.user.dateOfBirth
            ? dayjs(data.user.dateOfBirth)
            : null, // Convert API date to dayjs
        };

        setUserProfile(formattedData);
        setAvatarPreview(data.user.avatar || null);

        reset({
          ...formattedData,
          dateOfBirth: formattedData.dateOfBirth, // Ensure reset uses dayjs object
        });
      }
    } catch (error) {
      console.error("Error Fetching User Profile:", error);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, navigate, reset]);

  const onSubmit = async (data) => {
    setIsUpdating(true);

    try {
      let avatarUrl = userProfile?.avatar || null;
      if (avatarFile) {
        avatarUrl = await utilities.fileUtils.uploadFile(
          avatarFile,
          `/user/upload-profile-picture`,
          "image"
        );
      }

      const payload = {
        username: data.username,
        email: data.email,
        gender: data.gender,
        dateOfBirth: data.dateOfBirth
          ? data.dateOfBirth.toISOString() // Convert dayjs to ISO string
          : null,
        avatar: avatarUrl,
      };

      const { status, data: updatedData } = await dispatch(
        authController.editUserProfile({ payload, config: getAuthConfig() })
      ).unwrap();

      if (status === 200) {
        const formattedData = {
          ...updatedData,
          dateOfBirth: updatedData.dateOfBirth
            ? dayjs(updatedData.dateOfBirth)
            : null,
        };

        setUserProfile(formattedData);
        setAvatarPreview(updatedData.avatar);
        reset({
          ...formattedData,
          dateOfBirth: formattedData.dateOfBirth,
        });
      }
    } catch (error) {
      console.error("Error Updating Profile:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [fetchUserProfile]);

  const handleFileChange = (info) => {
    const file = info.file;
    setAvatarFile(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => setAvatarPreview(reader.result);
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="my-4">
      <h2 className="mb-4 fw-bold d-flex align-items-center">Edit Profile</h2>

      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="d-flex flex-column flex-sm-row justify-content-between mb-4">
            {/* Avatar Section */}
            <div
              className="d-flex justify-content-center mb-3 mb-sm-0"
              style={{ flex: 1 }}
            >
              <Avatar
                src={avatarPreview || "default-avatar-url.jpg"}
                size={150}
                alt="User Avatar"
              />
            </div>

            {/* Upload Section */}
            <div className="d-flex justify-content-center" style={{ flex: 1 }}>
              <Form.Item>
                <Upload
                  beforeUpload={() => false}
                  onChange={handleFileChange}
                  showUploadList={false}
                >
                  <Button icon={<UploadOutlined />}>Upload Avatar</Button>
                </Upload>
              </Form.Item>
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="g-2 m-0 p-0">
              {formFieldTypes.editProfileFields.map((field) => (
                <RenderField
                  key={field.name}
                  field={field}
                  control={control}
                  errors={errors}
                />
              ))}

              {/* Custom Date Picker for Date of Birth */}
              <Col lg={6} md={6} sm={12} xs={12}>
                <label className="fw-bold">Date of Birth</label>
                <Form.Item>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        size="large"
                        {...field}
                        format="YYYY-MM-DD"
                        value={field.value ? dayjs(field.value) : null} // Ensure dayjs object
                        onChange={(date) =>
                          field.onChange(date ? dayjs(date) : null)
                        }
                      />
                    )}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Button
              type="default"
              className="bg-corbeau text-ghostWhite fw-bold"
              htmlType="submit"
              size="large"
              loading={isUpdating}
            >
              {isUpdating ? "Saving..." : "Save Changes"}
            </Button>
          </form>
        </>
      )}
    </div>
  );
}

export default EditProfileSection;
