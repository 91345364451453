import React from "react";
import { List, Typography, Spin, Avatar, Button } from "antd";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

import { stringUtils } from "../../utils/utilities";
import { ROUTES } from "../../routes/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { isAuthenticated } from "../../utils/authHelpers";
import { StaticImages } from "../../utils/constants";

const { Title, Text } = Typography;

const PlaylistSongsSection = ({
  playlistSongs,
  isLoading,
  onDeleteSong = () => {}, // Function to handle song deletion
}) => {
  const navigate = useNavigate();

  const tableData = playlistSongs?.song_data?.map((song) => ({
    ...song,
    song: song,
  }));

  const isEmpty =
    !tableData || // Check if tableData is undefined or null
    tableData.length === 0 || // Check if tableData is an empty array
    (tableData.length === 1 && _.isEmpty(tableData[0])); // Check if the single object in the array is empty

  return (
    <Container>
      <Title level={1}>Songs</Title>

      {isLoading ? (
        <div className="d-flex justify-content-center">
          <Spin tip="Loading..." size="large" />
        </div>
      ) : isEmpty ? (
        <Text type="secondary">Playlist is empty</Text>
      ) : (
        <List
          itemLayout="horizontal"
          dataSource={tableData}
          renderItem={(song, index) => (
            <List.Item
              actions={[
                isAuthenticated().isUserAuthenticated && (
                  <Button
                    danger
                    type="link"
                    onClick={() => onDeleteSong(song?.song_id)} // Call the delete handler with song ID
                  >
                    Delete
                  </Button>
                ),
              ]}
            >
              <List.Item.Meta
                avatar={
                  <div className="d-flex align-items-center">
                    <Text strong className="mx-2">
                      {_.padStart(index + 1, 2, "0")}
                    </Text>
                    <Avatar
                      shape="square"
                      src={
                        song?.downloaded_album_image_link ||
                        StaticImages.App.AppDefaultS
                      }
                      size={50}
                    />
                  </div>
                }
                title={
                  <div className="d-flex align-items-center">
                    <Text
                      strong
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.LYRICS, {
                            songName: _.kebabCase(song?.song_name),
                            isrcKey: song?.isrc_code || song?.song_id,
                          })
                        )
                      }
                    >
                      {song.song_name || "Song Title"}
                    </Text>
                  </div>
                }
                description={
                  <div className="d-flex justify-content-between">
                    <Text
                      type="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.VIEW_ARTIST_BIO, {
                            artistName: _.kebabCase(song?.artist_name),
                            artistId: song?.artist_id,
                          })
                        )
                      }
                    >
                      {song.artist_name}
                    </Text>
                    <br />
                    <Text
                      type="secondary"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(
                          generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
                            albumName: _.kebabCase(song?.album_name),
                            albumId: song?.album_id,
                          })
                        )
                      }
                    >
                      {stringUtils.sanitizeName(song.album_name)}
                    </Text>
                  </div>
                }
              />
            </List.Item>
          )}
        />
      )}
    </Container>
  );
};

export default PlaylistSongsSection;
