import React, { memo } from "react";
import { Button, Card, Image, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsMusicNoteList } from "react-icons/bs";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

import utilities from "../../utils/utilities";
import { StaticImages } from "../../utils/constants";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import { ROUTES } from "../../routes/constants";

function AlbumCard({ album, index, type = "default" }) {
  const navigate = useNavigate();

  // Unified click handler
  const handleNavigation = () => {
    if (!album?.album_id || !album?.album_name) return;
    navigate(
      generateDynamicRoute(ROUTES.MAIN.ALBUM_SONGS, {
        albumName: utilities.stringUtils.formatStringType(
          album.album_name,
          "kebabCase"
        ),
        albumId: album.album_id,
      })
    );
  };

  // Reusable overlay for hover effects
  const renderOverlay = () => (
    <motion.div
      className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end p-2 bg-overlay"
      initial={{ opacity: 0 }}
      whileHover={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
    >
      <Button
        variant="light"
        className="fw-bold rounded-3"
        size="sm"
        onClick={(e) => {
          e.stopPropagation(); // Prevent parent click
          handleNavigation();
        }}
        aria-label={`View album: ${album.album_name}`}
      >
        <BsMusicNoteList className="me-2" />
        View Album
      </Button>
    </motion.div>
  );

  const albumImage =
    album?.downloaded_image_link || StaticImages.App.AppDefaultS;
  const albumName = album?.album_name || `Album ${index + 1}`;

  return (
    <Card
      className={`rounded-4 p-${
        type === "card" ? "3" : "2"
      } bg-white border-0 overflow-hidden album-card`}
      onClick={handleNavigation}
      role="button"
      aria-label={`Open album: ${albumName}`}
    >
      <motion.div
        className="position-relative"
        initial={{ scale: 1 }}
        whileHover={{ scale: 1.02 }}
      >
        {/* Album Image */}
        <Image
          src={albumImage}
          alt={albumName}
          className="w-100 h-100 rounded-4"
          style={{ objectFit: "cover" }}
          loading="lazy"
        />

        {/* Unified Overlay */}
        {renderOverlay()}
      </motion.div>

      <div className="py-2">
        {/* Album Title with Tooltip */}
        <OverlayTrigger
          placement="top-start"
          overlay={
            <Tooltip id={`tooltip-${index}`} className="text-white">
              {utilities.stringUtils.sanitizeName(albumName)}
            </Tooltip>
          }
        >
          <h6
            className="fw-bold text-truncate mt-2 mb-0"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent parent click
              handleNavigation();
            }}
          >
            {utilities.stringUtils.sanitizeName(albumName)}
          </h6>
        </OverlayTrigger>

        {/* View Album Button for List Type */}
        {type !== "card" && (
          <Button
            as={motion.button}
            variant="eigengrau"
            className="rounded-3 mt-2"
            size="sm"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 20,
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent parent click
              handleNavigation();
            }}
            aria-label={`View album: ${albumName}`}
          >
            <BsMusicNoteList className="me-2" />
            View Album
          </Button>
        )}
      </div>
    </Card>
  );
}

export default memo(AlbumCard);
