import utilities from "./utilities";

export const getSEOConfig = (type, data = {}) => {
  const {
    artistName = "Unknown artist",
    albumName = "Unknown album",
    songName = "Unknown song",
    isrcKey = "not-available",
    artistId = "not-available",
    albumId = "not-available",
    lyricsSnippet = "not-available",
    lyrics = "not-available",
  } = data;

  switch (type) {
    case "homePage":
      return {
        title: "Lyrics Web - Your Source for Latest Song Lyrics.",
        description:
          "Discover the latest song lyrics, albums by artist, trending music news, and artist information at Lyrics Web. Stay updated with music insights all in one place!",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com",
        author: "LyricsWeb",
        language: "en",
        ogTitle: "Lyrics Web - Your Source for Latest Song Lyrics.",
        ogDescription:
          "Discover the latest song lyrics, albums by artist, trending music news, and artist information at Lyrics Web. Stay updated with music insights all in one place!",
        ogImage: "/android-chrome-192x192.png",
        ogUrl: "https://www.lyricsweb.com",
        ogType: "website",
        ogLocale: "en_US",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "WebSite",
          name: "LyricsWeb",
          description:
            "Discover the latest song lyrics, albums by artist, trending music news, and artist information at Lyrics Web. Stay updated with music insights all in one place!",
          url: "https://www.lyricsweb.com",
          potentialAction: {
            "@type": "SearchAction",
            target:
              "https://www.lyricsweb.com/search?query={search_term_string}",
            "query-input": "required name=search_term_string",
          },
        },
        robots: "index, follow",
      };

    case "aboutPage":
      return {
        title: "About Us - Lyrics Web | Discover song Lyrics, Album & Artist",
        description:
          "Learn more about Lyrics Web, your go-to source for song lyrics & music information. Explore our mission to bring you the best lyrics & enhance your music experience.",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/about",
        author: "LyricsWeb",
        ogTitle: "About Us - Lyrics Web | Discover song Lyrics, Album & Artist",
        ogDescription:
          "Learn more about Lyrics Web, your go-to source for song lyrics & music information. Explore our mission to bring you the best lyrics & enhance your music experience.",
        ogImage: "/path/to/about-og-image.png",
        ogUrl: "https://www.lyricsweb.com/about",
        ogType: "website",
        robots: "index, follow",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "Organization",
          name: "LyricsWeb",
          url: "https://www.lyricsweb.com",
          description:
            "Lyrics Web is a leading source of song lyrics and music information.",
        },
      };

    case "awardsPage":
      return {
        title: "Lyrics Web Awards | Stay Updated With Top Music Achievements",
        description:
          "Discover the best in music with Lyrics Web awards page. Explore top artists, memorable songs, and outstanding achievements in the industry. Stay Updated with us!",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics, Awards, Music Industry Awards",
        canonical: "https://www.lyricsweb.com/awards",
        author: "LyricsWeb",
        ogTitle: "Lyrics Web Awards | Stay Updated With Top Music Achievements",
        ogDescription:
          "Discover the best in music with Lyrics Web awards page. Explore top artists, memorable songs, and outstanding achievements in the industry. Stay Updated with us!",
        ogImage: "/path/to/awards-og-image.png",
        ogUrl: "https://www.lyricsweb.com/awards",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: "Music Awards & Recognitions",
          description:
            "Explore the latest music awards, achievements, and recognitions in the music industry. Stay updated with artists' accomplishments and prestigious awards they have received.",
          url: "https://www.lyricsweb.com/awards",
        },
        robots: "index, follow",
      };

    case "privacyPage":
      return {
        title: "Privacy Policy | Lyrics Web - Your Data Protection Matters! ",
        description:
          "Read Lyrics Web privacy policy to understand how we protect your data & ensure your privacy. Learn about practices, your rights, & how we handle your information.",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/privacy-policy",
        author: "LyricsWeb",
        ogTitle: "Privacy Policy | Lyrics Web - Your Data Protection Matters!",
        ogDescription:
          "Read Lyrics Web privacy policy to understand how we protect your data & ensure your privacy. Learn about practices, your rights, & how we handle your information.",
        ogImage: "/path/to/privacy-og-image.png",
        ogUrl: "https://www.lyricsweb.com/privacy-policy",
        ogType: "website",
        robots: "index, follow",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: "Privacy Policy",
          description: "Learn how Lyrics Web protects your privacy.",
        },
      };

    case "termsPage":
      return {
        title:
          "Terms of Use - Lyrics Web | Your Source for Song Lyrics & Albums",
        description:
          "Read the Terms of Use for Lyrics Web. Understand our policies for using our song lyrics and related content. Your use of our site means you agree to these terms.",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/terms-of-use",
        author: "LyricsWeb",
        ogTitle:
          "Terms of Use - Lyrics Web | Your Source for Song Lyrics & Albums",
        ogDescription:
          "Read the Terms of Use for Lyrics Web. Understand our policies for using our song lyrics and related content. Your use of our site means you agree to these terms.",
        ogImage: "/path/to/terms-og-image.png",
        ogUrl: "https://www.lyricsweb.com/terms-of-use",
        ogType: "website",
        robots: "index, follow",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: "Terms of Use",
          description:
            "The terms and conditions for using the Lyrics Web platform.",
        },
      };

    case "loginPage":
      return {
        title:
          "Login to Lyrics Web- Access Your Favorite Lyrics, Albums & Artist",
        description:
          "Log in to Lyrics Web to enjoy seamless access to your favorite song lyrics, Music Albums. Connect with your account and discover a world of music. Join us today!",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/auth/login",
        author: "LyricsWeb",
        ogTitle:
          "Login to Lyrics Web- Access Your Favorite Lyrics, Albums & Artist",
        ogDescription:
          "Log in to Lyrics Web to enjoy seamless access to your favorite song lyrics, Music Albums. Connect with your account and discover a world of music. Join us today!",
        ogImage: "/path/to/login-og-image.png",
        ogUrl: "https://www.lyricsweb.com/auth/login",
        ogType: "website",
        robots: "noindex, follow", // Usually login pages are not indexed
        structuredData: {
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: "Login Page",
          description:
            "Log in to Lyrics Web to enjoy seamless access to your favorite song lyrics, Music Albums. Connect with your account and discover a world of music. Join us today!",
        },
      };

    case "signupPage":
      return {
        title: "Sign Up for Lyrics Web - Access Your Favorite Lyrics Easily",
        description:
          "Join Lyrics Web today! Create an account to access, save, and share your favorite song lyrics effortlessly. Enjoy a seamless experience with user-friendly platform.",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/auth/signup",
        author: "LyricsWeb",
        ogTitle: "Sign Up for Lyrics Web - Access Your Favorite Lyrics Easily",
        ogDescription:
          "Join Lyrics Web today! Create an account to access, save, and share your favorite song lyrics effortlessly. Enjoy a seamless experience with user-friendly platform.",
        ogImage: "/path/to/signup-og-image.png",
        ogUrl: "https://www.lyricsweb.com/auth/signup",
        ogType: "website",
        robots: "noindex, follow", // Usually signup pages are not indexed
        structuredData: {
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: "Sign Up Page",
          description:
            "Join Lyrics Web today! Create an account to access, save, and share your favorite song lyrics effortlessly. Enjoy a seamless experience with user-friendly platform.",
        },
      };

    case "contactPage":
      return {
        title:
          "Contact Us - Lyrics Web | Reach Out for Lyrics & Music Support!",
        description:
          "Have questions or need support? Contact Lyrics Web for quick assistance. Our team is here to help with lyrics and music-related inquiries. Get in touch with us.",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/contact-us",
        author: "LyricsWeb",
        ogTitle:
          "Contact Us - Lyrics Web | Reach Out for Lyrics & Music Support!",
        ogDescription:
          "Have questions or need support? Contact Lyrics Web for quick assistance. Our team is here to help with lyrics and music-related inquiries. Get in touch with us.",
        ogImage: "/path/to/contact-og-image.png",
        ogUrl: "https://www.lyricsweb.com/contact-us",
        ogType: "website",
        robots: "index, follow",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "ContactPage",
          name: "Contact Us",
          description:
            "Have questions or need support? Contact Lyrics Web for quick assistance. Our team is here to help with lyrics and music-related inquiries. Get in touch with us.",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+1-800-555-5555",
            contactType: "Customer Service",
          },
        },
      };

    case "testimonialsPage":
      return {
        title: "Customer Testimonials - Hear What Our Users Say | Lyrics Web",
        description:
          "Read real customer testimonials on Lyrics Web. Discover how our platform has helped users enhance their music experience and find the Song lyrics, Albums by Artist.",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/testimonials",
        author: "LyricsWeb",
        ogTitle: "Customer Testimonials - Hear What Our Users Say | Lyrics Web",
        ogDescription:
          "Read real customer testimonials on Lyrics Web. Discover how our platform has helped users enhance their music experience and find the Song lyrics, Albums by Artist. ",
        ogImage: "/path/to/testimonials-og-image.png",
        ogUrl: "https://www.lyricsweb.com/testimonials",
        ogType: "website",
        robots: "index, follow",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "Review",
          itemReviewed: { "@type": "Organization", name: "Lyrics Web" },
          reviewBody: "Amazing platform for finding song lyrics!",
          author: { "@type": "Person", name: "Jane Doe" },
        },
      };

    case "newsPage":
      return {
        title:
          "Latest Music News, Album Releases & Artist Updates | Lyrics Web",
        description:
          "Stay updated with the latest music news, album releases, artist Updates and industry trends. Discover new stories and insights from the world of music on LyricsWeb.",
        keywords:
          "lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics",
        canonical: "https://www.lyricsweb.com/news",
        author: "LyricsWeb",
        ogTitle:
          "Latest Music News, Album Releases & Artist Updates | Lyrics Web",
        ogDescription:
          "Stay updated with the latest music news, album releases, artist Updates and industry trends. Discover new stories and insights from the world of music on LyricsWeb.",
        ogImage: "/path/to/news-og-image.png",
        ogUrl: "https://www.lyricsweb.com/news",
        ogType: "website",
        robots: "index, follow",
        structuredData: {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          headline: "Latest Music News",
          datePublished: "2024-09-01",
          author: { "@type": "Person", name: "Lyrics Web Team" },
          publisher: {
            "@type": "Organization",
            name: "Lyrics Web",
            logo: { "@type": "ImageObject", url: "/path/to/logo.png" },
          },
        },
      };

    case "artistPage":
      return {
        title:
          artistName.length === 1
            ? `Lyrics Web - Discover Song Lyrics by Alphabetical order ${utilities.stringUtils.formatStringType(
                artistName
              )}`
            : `Get Albums of ${utilities.stringUtils.formatStringType(
                artistName
              )} - Explore Song Lyrics on Lyrics Web`,
        description:
          artistName.length === 1
            ? `Discover lyrics from albums starting with ${utilities.stringUtils.formatStringType(
                artistName
              )}. Join the Lyrics Web community to explore, understand, & connect with fellow music enthusiasts!`
            : `Explore all albums by ${utilities.stringUtils.formatStringType(
                artistName
              )} and dive into their lyrics with the Lyrics Web community. Discover song meanings, explore tracks, & connect with fellow music enthusiasts!`,
        keywords: `lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics, ${utilities.stringUtils.formatStringType(
          artistName
        )} music albums, music albums by ${utilities.stringUtils.formatStringType(
          artistName
        )}, ${utilities.stringUtils.formatStringType(artistName)}, ${utilities.stringUtils.formatStringType(
          artistName
        )} albums, ${utilities.stringUtils.formatStringType(artistName)} album song lyrics`,
        canonical:
          artistName.length === 1
            ? `https://www.lyricsweb.com/artist/${utilities.stringUtils.formatStringType(
                artistName,
                "kebabCase"
              )}`
            : `https://www.lyricsweb.com/artist/${utilities.stringUtils.formatStringType(
                artistName,
                "kebabCase"
              )}/${artistId}`,
        author: utilities.stringUtils.formatStringType(artistName),
        ogTitle:
          artistName.length === 1
            ? `Lyrics Web - Discover Song Lyrics by Alphabetical order ${utilities.stringUtils.formatStringType(
                artistName
              )}`
            : `Get Albums of ${utilities.stringUtils.formatStringType(
                artistName
              )} - Explore Song Lyrics on Lyrics Web`,
        ogDescription:
          artistName.length === 1
            ? `Discover lyrics from albums starting with ${utilities.stringUtils.formatStringType(
                artistName
              )}.`
            : `Explore all albums by ${utilities.stringUtils.formatStringType(artistName)}.`,
        ogImage: "/path/to/artist-og-image.png",
        ogUrl:
          artistName.length === 1
            ? `https://www.lyricsweb.com/artist/${utilities.stringUtils.formatStringType(
                artistName,
                "kebabCase"
              )}`
            : `https://www.lyricsweb.com/artist/${utilities.stringUtils.formatStringType(
                artistName,
                "kebabCase"
              )}/${artistId}`,
        structuredData: {
          "@context": "https://schema.org",
          "@type": "MusicGroup",
          name: utilities.stringUtils.formatStringType(artistName),
          genre: "Pop", // Adjust accordingly
          sameAs: `https://www.lyricsweb.com/artist/${utilities.stringUtils.formatStringType(
            artistName,
            "kebabCase"
          )}`,
        },
      };

    case "albumPage":
      return {
        title: `Get Songs & Songs Lyrics from ${utilities.stringUtils.formatStringType(
          albumName
        )} | Lyrics Web`,
        description: `Discover lyrics for all songs from ${utilities.stringUtils.formatStringType(
          albumName
        )} and join the Lyrics Web community to explore the meaning behind each track. Connect with fellow music enthusiasts!`,
        keywords: `lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics, ${utilities.stringUtils.formatStringType(
          albumName
        )} songs, songs of ${utilities.stringUtils.formatStringType(albumName)}, ${utilities.stringUtils.formatStringType(
          albumName
        )} album, ${utilities.stringUtils.formatStringType(albumName)}`,
        canonical: `https://www.lyricsweb.com/album/${utilities.stringUtils.formatStringType(
          albumName,
          "kebabCase"
        )}/${albumId}`,
        author: utilities.stringUtils.formatStringType(albumName),
        ogTitle: `Get Songs & Songs Lyrics from ${utilities.stringUtils.formatStringType(
          albumName
        )} | Lyrics Web`,
        ogDescription: `Discover lyrics for all songs from ${utilities.stringUtils.formatStringType(
          albumName
        )} and join the Lyrics Web community to explore the meaning behind each track. Connect with fellow music enthusiasts!`,
        ogImage: "/path/to/album-og-image.png",
        ogUrl: `https://www.lyricsweb.com/album/${utilities.stringUtils.formatStringType(
          albumName,
          "kebabCase"
        )}/${albumId}`,
        structuredData: {
          "@context": "https://schema.org",
          "@type": "MusicAlbum",
          name: albumName,
          byArtist: { "@type": "MusicGroup", name: artistName },
        },
      };

    case "songPage":
      return {
        title: `${songName} Lyrics by ${artistName} | Lyrics Web`,
        description: `${songName} Lyrics: ${lyricsSnippet}`,
        keywords: `lyricsweb, lyrics web, lyrics, song lyrics, music albums, artists, latest songs, popular song lyrics, Music lyrics search, Song lyric finder, Song lyrics search, Famous lyrics, Top artists, Lyrics meaning, Best song lyrics, ${songName} by ${artistName}, song lyrics, popular song lyrics, ${songName} lyrics, lyrics of ${songName} song`,
        canonical: `https://www.lyricsweb.com/lyrics/${utilities.stringUtils.formatStringType(
          songName,
          "kebabCase"
        )}/${isrcKey}`,
        author: artistName,
        ogTitle: `${songName} Lyrics - ${artistName} | Lyrics Web`,
        ogDescription: `${songName} Lyrics: ${lyricsSnippet}`,
        ogImage: "/favicon-32x32.png",
        ogUrl: `https://www.lyricsweb.com/lyrics/${utilities.stringUtils.formatStringType(
          songName,
          "kebabCase"
        )}/${isrcKey}`,
        structuredData: {
          "@context": "https://schema.org",
          "@type": "MusicComposition",
          name: songName,
          composer: { "@type": "Person", name: artistName },
          lyrics: { "@type": "CreativeWork", text: lyrics },
        },
      };

    default:
      return {};
  }
};
