import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { letterNavLinks } from "../../utils/constants";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import styles from "./TopBar.module.css";
import { useSelector } from "react-redux";
import { ROUTES } from "../../routes/constants";
import AlphabetNav from "./AlphabetNav";
import { Button, Dropdown, Input, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { generateDynamicRoute } from "../../utils/generateDynamicRoute";
import _ from "lodash";
import utilities from "../../utils/utilities";

const TopBar = () => {
  const { genres = [] } = useSelector((state) => state.config);
  const { genreName: genre } = useParams();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");
  const [filteredGenres, setFilteredGenres] = useState(genres);
  const [selectedGenre, setSelectedGenre] = useState(genre || "all");

  useEffect(() => {
    setFilteredGenres(
      genres.filter((g) =>
        g?.genreName.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  }, [searchValue, genres]);

  useEffect(() => {
    if (genre) {
      setSelectedGenre(genre.toLowerCase());
    }
  }, [genre]);

  const handleGenreSelect = (selected) => {
    const selectedGenre = selected.toLowerCase();
    setSelectedGenre(selectedGenre);
    // navigate(`/genre/${selectedGenre}`);
    navigate(
      generateDynamicRoute(ROUTES.MAIN.FILTER_GENRES, {
        genreName: utilities.stringUtils.formatStringType(selectedGenre, "kebabCase"),
      })
    );
  };

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const isFixedGenre = (genre) => ["all", "pop"].includes(genre);

  const GenreButton = ({ genre, isSelected, onClick }) => (
    <Button
      className="mx-1 p-2 rounded-3 fw-semibold border-2 border-black"
      variant={isSelected ? "solid" : "default"}
      // type={isSelected ? "primary" : "default"}
      color={isSelected ? "default" : "default"}
      onClick={onClick}
    >
      {_.startCase(_.replace(genre, /[-/,\s]+/g, " "))}
    </Button>
  );

  const renderDropdownMenu = () => (
    <Menu>
      <div className={styles.dropdownSearch}>
        <Input
          placeholder="Search genres"
          value={searchValue}
          onChange={handleSearchChange}
        />
      </div>
      <div className={`${styles.dropdownList}`}>
        {filteredGenres.map((genre) => (
          <Menu.Item
            key={genre.genreName}
            onClick={() => handleGenreSelect(genre.genreName)}
          >
            {genre.genreName}
          </Menu.Item>
        ))}
      </div>
    </Menu>
  );

  return (
    <>
      <div className="d-flex justify-content-between align-items-center flex-wrap">
        <div className="mx-3 mx-lg-0 my-3 d-flex justify-content-start flex-wrap">
          <GenreButton
            genre="all"
            isSelected={selectedGenre === "all"}
            onClick={() => {
              setSelectedGenre("all");
              navigate(ROUTES.MAIN.HOME);
            }}
          />
          <GenreButton
            genre="pop"
            isSelected={selectedGenre === "pop"}
            onClick={() => handleGenreSelect("pop")}
          />
          {!isFixedGenre(selectedGenre) && (
            <GenreButton
              genre={selectedGenre}
              isSelected={true}
              onClick={() => navigate(`/genre/${selectedGenre}`)}
            />
          )}
          <Dropdown overlay={renderDropdownMenu()} trigger={["click"]}>
            <Button className="mx-1 p-2 rounded-3 fw-semibold border-2 border-black">
              More <DownOutlined />
            </Button>
          </Dropdown>
        </div>

        <div className="mx-auto d-none d-lg-flex flex-wrap">
          {letterNavLinks.map((item, index) => (
            <NavLink
              key={index}
              to={item.path}
              className={styles.navLink}
              style={{ whiteSpace: "nowrap" }}
            >
              {item.label}
            </NavLink>
          ))}
        </div>
      </div>
      <div className="d-block d-lg-none">
        <Container fluid className="py-4">
          <AlphabetNav />
        </Container>
      </div>
    </>
  );
};

export default TopBar;
