import React, { useState } from "react";
import {
  Button,
  Card,
  Container,
  Image,
  Nav,
  Navbar,
  NavLink,
} from "react-bootstrap";
import { Button as AntButton, Dropdown, Menu } from "antd";
import {
  FaBarsStaggered,
  FaList,
  FaRegCircleUser,
  FaXmark,
} from "react-icons/fa6";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "../../routes/constants";
import { StaticImages } from "../../utils/constants";
import AppSearchBar from "../AppSearchBar/AppSearchBar";
import { getAuthToken, isAuthenticated } from "../../utils/authHelpers";

function AppNavigation() {
  const navigate = useNavigate();

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const NavButton = ({ to, children }) => {
    const navigate = useNavigate();
    return (
      <Button
        variant="link"
        className="text-black text-decoration-none"
        onClick={() => navigate(to)}
      >
        {children}
      </Button>
    );
  };

  // Create a dropdown menu for logged-in users
  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => navigate(ROUTES.AUTH.PROFILE_PREVIEW)}>
        <FaRegCircleUser className="fs-5 me-2" />
        Profile
      </Menu.Item>
      <Menu.Item key="2" onClick={() => navigate(ROUTES.MAIN.USER_PLAYLISTS)}>
        <FaList className="fs-5 me-2" />
        My Playlists
      </Menu.Item>
      <Menu.Item
        key="3"
        onClick={() => {
          // Add your logout logic here
          localStorage.clear();
          navigate(ROUTES.AUTH.LOGIN);
        }}
      >
        <MdLogout className="fs-5 me-2" />
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Navbar expand="lg" className="bg-body-white rounded-4 shadow">
        <Container>
          <Navbar.Brand as={NavLink} onClick={() => navigate(ROUTES.MAIN.HOME)}>
            <Image
              src={StaticImages.App.AppLogoLyrics}
              alt="App Logo"
              loading="lazy"
            />
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="border-0"
            onClick={toggleNavbar}
          >
            <AntButton
              variant="solid"
              color="default"
              className="d-flex align-items-center"
            >
              {isNavbarOpen ? <FaXmark /> : <FaBarsStaggered />}
            </AntButton>
          </Navbar.Toggle>

          {/* Collapsible Navbar Content */}
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="w-100">
              {/* Search Bar */}
              <div className="d-none d-lg-block d-lg-flex justify-content-center flex-grow-1 mx-4">
                <AppSearchBar />
              </div>

              {/* Navigation Buttons */}
              <div className="d-lg-flex justify-content-end align-items-center mt-3 mt-lg-0">
                {/* Links visible only on small screens */}
                <div className="d-lg-none mb-3 d-flex flex-column">
                  <NavButton to={ROUTES.MAIN.HOME}>Home</NavButton>
                  <NavButton to={ROUTES.MAIN.ABOUT_US}>About Us</NavButton>
                  <NavButton to={ROUTES.MAIN.CONTACT_US}>Contact Us</NavButton>
                  <NavButton to={ROUTES.MAIN.HELP}>Help</NavButton>

                  <Card className="px-5 py-3 my-4 mx-5 rounded-4 shadow">
                    {isAuthenticated().isUserAuthenticated ? (
                      <div className="d-flex flex-column justify-content-start">
                        <AntButton
                          type="text"
                          className="m-2 d-flex align-items-center justify-content-start"
                          onClick={() => navigate(ROUTES.AUTH.PROFILE_PREVIEW)}
                        >
                          <FaRegCircleUser className="fs-3 me-2" />
                          Profile
                        </AntButton>

                        <AntButton
                          type="text"
                          className="m-2 d-flex align-items-center justify-content-start"
                          onClick={() => navigate(ROUTES.MAIN.USER_PLAYLISTS)}
                        >
                          <FaList className="fs-3 me-2" />
                          My Playlists
                        </AntButton>

                        <AntButton
                          type="primary"
                          danger
                          className="m-2 d-flex align-items-center justify-content-start"
                          onClick={() => {
                            // Add your logout logic here
                            localStorage.clear();
                            navigate(ROUTES.AUTH.LOGIN);
                          }}
                        >
                          <MdLogout className="fs-3 me-2" />
                          Logout
                        </AntButton>
                      </div>
                    ) : (
                      <>
                        <NavButton to={ROUTES.AUTH.LOGIN} className="me-2">
                          Login
                        </NavButton>
                        <Button
                          variant="eigengrau"
                          onClick={() => navigate(ROUTES.AUTH.SIGNUP)}
                        >
                          Sign Up
                        </Button>
                      </>
                    )}
                  </Card>
                </div>

                <div className="d-none d-lg-block">
                  {/* Login and Sign Up */}
                  {getAuthToken() ? (
                    <Dropdown
                      overlay={menu}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <Button
                        variant="link"
                        className="d-flex align-items-center"
                      >
                        <FaRegCircleUser className="fs-3" />
                      </Button>
                    </Dropdown>
                  ) : (
                    <>
                      <NavButton to={ROUTES.AUTH.LOGIN} className="me-2">
                        Login
                      </NavButton>
                      <Button
                        variant="eigengrau"
                        onClick={() => navigate(ROUTES.AUTH.SIGNUP)}
                      >
                        Sign Up
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="d-lg-none mx-4 my-2">
        <AppSearchBar />
      </div>
    </>
  );
}

export default AppNavigation;
