import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";

import MainLayout from "../../../layouts/MainLayout";
import TopBar from "../../../components/_buttons/TopBar";
import { generateDynamicRoute } from "../../../utils/generateDynamicRoute";
import { ROUTES } from "../../../routes/constants";
import GenresPageQuiz from "../../../components/_sections/GenresPageQuiz";
import utilities from "../../../utils/utilities";

function GenresPage() {
  const {
    genres = [],
    isLoading,
    error,
  } = useSelector((state) => state.config);
  const navigate = useNavigate();

  return (
    <MainLayout>
      <Container fluid className="m-0 p-0">
        <div className="m-2">
          <TopBar />
        </div>

        <div>
          <Row className="m-0 p-0">
            <div className="my-5">
              <h1 className="fw-bold">Genres</h1>
            </div>
            <Col lg={7} md={7} sm={12} xs={12}>
              <Row>
                {isLoading ? (
                  <Col className="d-flex justify-content-center">
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ minHeight: "25vh", width: "100%" }}
                    >
                      <Spin tip="Loading..." size="large" />
                    </div>{" "}
                  </Col>
                ) : error ? (
                  <Col className="text-danger">{error}</Col>
                ) : genres.length > 0 ? (
                  genres.map((genre, index) => (
                    <Col
                      key={index}
                      lg={4}
                      md={4}
                      sm={12}
                      xs={12}
                      className="mb-3"
                    >
                      <Button
                        as={motion.button}
                        variant="link"
                        className="w-100 fw-bold py-2 fs-6 text-decoration-none text-eigengrau text-start"
                        size="lg"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        transition={{
                          type: "spring",
                          stiffness: 300,
                          damping: 20,
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate(
                            generateDynamicRoute(ROUTES.MAIN.FILTER_GENRES, {
                              genreName: utilities.stringUtils.formatStringType(
                                genre.genreName,
                                "kebabCase"
                              ),
                            })
                          )
                        }
                      >
                        {genre.genreName || "Unknown"}
                      </Button>
                    </Col>
                  ))
                ) : (
                  <Col className="text-center">No genres available.</Col>
                )}
              </Row>
            </Col>

            <Col lg={5} md={5} sm={12} xs={12}>
              <GenresPageQuiz />
            </Col>
          </Row>
        </div>
      </Container>
    </MainLayout>
  );
}

export default GenresPage;
